.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;
}

footer {
    width: 100%;
    justify-content: center;
    background-color: white;
    text-align: center;
}